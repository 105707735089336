import { useTheme } from '@aws-amplify/ui-react';
import React from 'react';
import styled, { css } from 'styled-components';
import { PrismicLink } from '@prismicio/react';
import { linkResolver } from '../../prismicio';

interface ButtonProps {
  $hasIcon: any;
  $fullWidth: any;
  $menuCta: boolean;
}

const PrimaryButtonStyles = styled.div<ButtonProps>`
  a {
    background-color: ${(props) =>
      props?.theme.tokens.colors.buttonPrimary.value};
    color: ${(props) => props?.theme.tokens.colors.black.value} !important;
    padding: 10px 28px;
    border: none;
    border-radius: 3px;
    display: inline-block;

    &:hover {
      background-color: ${(props) => props?.theme.tokens.colors.white.value};
      color: ${(props) => props?.theme.tokens.colors.black.value} !important;
    }

    &:visited {
      color: ${(props) => props?.theme.tokens.colors.black.value} !important;
    }

    ${({ $hasIcon }: any) =>
      $hasIcon &&
      css`
        position: relative;
        padding: 14px 46px 14px 16px;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          width: 28px;
          height: 28px;
          background-image: url('../../images/button-icon.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          transition: transform ease-in-out 300ms;
        }

        &:hover {
          &::after {
            transform: translateY(-50%) translateX(7px);
          }
        }
      `}

    ${({ $fullWidth }: any) =>
      $fullWidth &&
      css`
        width: 100%;
        border-radius: 0;
        text-align: center;
      `}

    ${({ $menuCta }) =>
      $menuCta &&
      css`
        text-transform: uppercase;
        padding: 1rem 0.875rem 0.625rem;
        width: 100%;
        text-align: center;

        p {
          font-size: 0.875rem;
          font-family: var(--fonts-default-title);
        }
      `}
  }
`;

export default function PrimaryButton({
  children,
  fullWidth,
  hasIcon,
  menuCta,
  ...props
}: any) {
  const theme = useTheme();

  return (
    <PrimaryButtonStyles
      theme={theme}
      $hasIcon={hasIcon}
      $fullWidth={fullWidth}
      $menuCta={menuCta}
    >
      <PrismicLink {...props} linkResolver={linkResolver}>{children}</PrismicLink>
    </PrimaryButtonStyles>
  );
}
