import { Flex, Link, useTheme } from '@aws-amplify/ui-react';
import styled from 'styled-components';
import HtmlTitle from '@components/title/htmlTitle';
import HtmlText from '@components/text/htmlText';
import { media } from '@theme/mediaQueries';
// import Link from 'next/link';
import Image from 'next/image';
import ClippedImage from '@components/image/clippedImage';

interface MenuCardProps {
  image: any;
  title: string;
  description?: string;
  link: string;
  uid: string;
  alias?: string;
}

const CardStyles = styled.div`
  display: flex;
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: -0.625rem;
    left: -0.75rem;
    bottom: -0.625rem;
    right: -0.75rem;
  }

  :hover {
    &:before {
      background-color: ${(props) =>
        props.theme.tokens.colors.background.quaternary.value};
      z-index: -10;
    }

    .amplify-heading {
      color: ${(props) => props.theme.tokens.colors.wwiGreen.value};
    }
  }
`;

const CardLinkStyles = styled(Link)`
  position: relative;
  width: 100%;
`;

const CardTextStyled = styled.div`
  display: none;

  ${media('medium')`
    display: block;
  `}
`;

const MenuCard = ({ image, title, description, link, uid, alias }: MenuCardProps) => {
  const theme = useTheme();

  return (
    <CardStyles theme={theme}>
      <CardLinkStyles href={link}>
        {image?.url && (
          <ClippedImage uid={uid}>
            <Image
              src={image.url}
              alt={image.alt || ''}
              width={image.dimensions.width}
              height={image.dimensions.height}
            />
          </ClippedImage>
        )}
        <Flex direction="column" alignItems="flex-start" gap="0">
          <div>
            <HtmlTitle type="heading5">{title}</HtmlTitle>
            <CardTextStyled>
              <HtmlText field={description} />
            </CardTextStyled>
          </div>
        </Flex>
      </CardLinkStyles>
    </CardStyles>
  );
};

export default MenuCard;
