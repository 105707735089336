import React from 'react';

import DownloadIcon from '@public/images/download.svg';
import LogoIcon from '@public/images/logo.svg';
import RockIcon from '@public/images/rocks.svg';
import BoltIcon from '@public/images/bolt.svg';
import AccentsIcon from '@public/images/accents.svg';
import AccentsTwoIcon from '@public/images/accents-two.svg';
import LinkedinIcon from '@public/images/linkedin-icon.svg';
import TwitterIcon from '@public/images/twitter-icon.svg';
import FacebookIcon from '@public/images/facebook-icon.svg';
import LinkIcon from '@public/images/link.svg';

export const Logo = () => <LogoIcon className="wwi-icon wwi-icon--logo" />;
export const Rocks = () => <RockIcon className="wwi-icon wwi-icon--rocks" />;
export const Bolt = () => <BoltIcon className="wwi-icon wwi-icon--bolt" />;
export const Accent = () => (
  <AccentsIcon className="wwi-icon wwi-icon--accents" />
);
export const AccentPng = require('@public/images/accents.png');
export const AccentTwo = () => (
  <AccentsTwoIcon className="wwi-icon wwi-icon--accents-two" />
);
export const Download = () => (
  <DownloadIcon className="wwi-icon wwi-icon--download" />
);
export const Linkedin = () => (
  <LinkedinIcon className="wwi-icon wwi-icon--linkedin" />
);
export const Twitter = () => (
  <TwitterIcon className="wwi-icon wwi-icon--twitter" />
);
export const Facebook = () => (
  <FacebookIcon className="wwi-icon wwi-icon--facebook" />
);
export const Link = () => <LinkIcon className="wwi-icon wwi-icon--link" />;
