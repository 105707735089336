import { Text, View, useTheme } from '@aws-amplify/ui-react';
import styled from 'styled-components';
import { media } from '@theme/mediaQueries';
import Image from 'next/image';

import { Rocks, Bolt, AccentPng } from '@components/utilities/icons';
import ImageCredits from '@components/image-credits/imageCredits';

interface ImageProps {
  readonly backgroundColor: string;
  readonly background: string;
}

const ImageStyles = styled.div<ImageProps>`
  position: relative;

  ${({ type }: any) =>
    type == 'htmlImage' &&
    `
    overflow: hidden;
  `}

  .wwi-image {
    &__icons {
      position: absolute;
      z-index: 1;
    }

    &__rocks {
      bottom: -47px;
      right: 0;
      width: 180px;
      height: 100px;

      svg {
        path {
          fill: ${(props): any =>
            props.background
              ? props.background
              : props?.theme?.tokens?.colors?.background?.primary.value};
        }
      }
    }

    &__accents {
      top: -70px;
      width: 441px;

      &--right {
        right: -289px;
      }

      &--left {
        left: -280px;
        transform: rotateY(180deg);
      }
    }

    &__bolt {
      left: 0;
      bottom: -80px;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .amplify-image {
    max-width: 100%;
    height: auto;
  }

  ${media('medium')`
    .wwi-image {
      &__rocks {
        height: 170px;
        width: 470px;
      }

      &__accents {
        &--right {
          right: -314px;
        }

        &--left {
          left: -320px;
        }
      }

      &__bolt {
        left: 0;
        bottom: 33px;
      }
    }
  `};
`;

const CaptionStyles = styled.div`
  font-size: var(--font-size-label);
  margin-top: 30px;
  position: relative;
  z-index: 1;
`;

export const DefaultImage = ({
  type,
  src,
  srcset,
  alt,
  width,
  height,
  caption,
  copyright,
  position,
  ...props
}: any) => {
  const theme = useTheme();

  const updateImageOverlay = () => {
    switch (type) {
      case 'bolt':
        return (
          <View as="div" className="wwi-image__bolt wwi-image__icons">
            <Bolt />
          </View>
        );
      case 'accents-left':
        return (
          <View
            as="div"
            className="wwi-image__accents wwi-image__accents--left wwi-image__icons"
          >
            <Image src={AccentPng} alt="" />
          </View>
        );
      case 'accents-right':
        return (
          <View
            as="div"
            className="wwi-image__accents wwi-image__accents--right wwi-image__icons"
          >
            <Image src={AccentPng} alt="" />
          </View>
        );
      default:
        return (
          <View as="div" className="wwi-image__rocks wwi-image__icons">
            <Rocks />
          </View>
        );
    }
  };

  return (
    <>
      <ImageStyles className="wwi-image" theme={theme} type={type} {...props}>
        <Image
          src={src}
          // srcSet={srcset}
          alt={alt ? alt : ''}
          width={width}
          height={height}
        />
        <>{updateImageOverlay()}</>
        {copyright ? (
          <ImageCredits copyright={copyright} position={position} />
        ) : (
          ''
        )}
      </ImageStyles>

      {caption && (
        <CaptionStyles>
          <Text>{caption}</Text>
        </CaptionStyles>
      )}
    </>
  );
};
