import { Link } from "@aws-amplify/ui-react";
import { PrismicLink } from "@prismicio/react";
import { linkResolver } from "../../prismicio";

const LocalLink = ({ link, children, className }: any) => {
    if (typeof link == 'string') {
        return <Link className={className} href={link}>{children}</Link>;
    } else if (link.isBroken || link.link_type == 'Any') {
        return <span className={className}>{children}</span>
    } else {
        return <PrismicLink linkResolver={linkResolver} className={className} field={link}>{children}</PrismicLink>;
    }
};

export default LocalLink;