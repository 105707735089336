import { css } from 'styled-components';
import { breakpoints } from '.';

export const media =
  (key: keyof typeof breakpoints) =>
  (string: TemplateStringsArray, ...style: any[]) =>
    css`
      @media screen and (min-width: ${breakpoints[key]}px) {
        ${css(string, ...style)}
      }
    `;
