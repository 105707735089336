import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@aws-amplify/ui-react';

interface CreditsProps {
  position?: string;
}

const CreditStyles = styled.div<CreditsProps>`
  padding: 6px 10px;
  background-color: ${(props) =>
    props?.theme.tokens.colors.background.tertiary.value};
  color: ${(props) => props?.theme.tokens.colors.white.value};
  text-align: right;
  font-size: var(--font-size-label--mobile);

  ${({ position }: any) =>
    position == 'overlay-top' &&
    `
      position: absolute;
      top: 0;
      right: 0;
      max-width: 557px;
      z-index: 1;
    `}

  ${({ position }: any) =>
    position == 'bottom' &&
    `
      position: relative;
      bottom: 7px;
      right: 0;
      width: 100%;
    `}
`;

const ImageCredits = ({ copyright, position }: any) => {
  const theme = useTheme();
  return (
    <CreditStyles theme={theme} position={position}>
      {copyright}
    </CreditStyles>
  );
};

export default ImageCredits;
