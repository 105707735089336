import { View } from '@aws-amplify/ui-react';

type ContainerProps = {
  children: React.ReactNode;
};

export default function Container({ children, ...styles }: ContainerProps) {
  return (
    <View
      as="div"
      className="container container2"
      margin={'0 auto'}
      padding={{
        base: '0 25px',
        large: '0 90px',
        xl: '0 96px',
        xxl: '0 76px',
      }}
      width="100%"
      maxWidth={{
        base: '100%',
        small: '540px',
        medium: '720px',
        large: '960px',
        xl: '1140px',
        xxl: '1320px',
      }}
      minHeight="100%"
      {...styles}
    >
      {children}
    </View>
  );
}
