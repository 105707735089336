import styled from 'styled-components';
import { media } from '@theme/mediaQueries';
import { ReactNode } from 'react';
import { View } from '@aws-amplify/ui-react';

type ClippedImageProps = {
  children: ReactNode;
  uid: string;
};

const ClippedImageStyled = styled(View)<ClippedImageProps>`
  padding-top: 56.25%;
  position: relative;
  display: flex;
  margin-bottom: 0.625rem;

  img {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    object-fit: cover;
    clip-path: url(#${(props) => `mask_${props.uid}`});
    border-radius: 2px;
  }

  ${media('medium')`
    padding-top: 72%;
    margin-bottom: 1.25rem;
  `};
`;

export default function ClippedImage({ children, uid }: ClippedImageProps) {
  return (
    <ClippedImageStyled uid={uid}>
      {children}
      <svg width="0" height="0">
        <clipPath id={`mask_${uid}`} clipPathUnits="objectBoundingBox">
          <path d="M0.495008 1H0.00754717C0.00337899 1 0 0.99529 0 0.989474V0.0105263C0 0.0047128 0.00337895 0 0.00754713 0H0.992453C0.996623 0 1 0.00471278 1 0.0105263V0.989474C1 0.99529 0.996623 1 0.992453 1H0.978815C0.976068 1 0.973536 0.997916 0.972208 0.994558L0.958475 0.959821C0.955887 0.953268 0.949328 0.952563 0.946049 0.95849C0.941064 0.9675 0.930815 0.960389 0.932958 0.949405L0.951792 0.852989C0.952528 0.849211 0.951706 0.845179 0.949649 0.842511L0.904698 0.784195C0.901045 0.779458 0.895162 0.780874 0.89286 0.787047L0.850721 0.900116C0.850525 0.900637 0.850298 0.901137 0.850045 0.901611L0.829808 0.938937C0.825442 0.946989 0.816238 0.942684 0.816238 0.93259V0.853895C0.816238 0.849632 0.814396 0.845784 0.81157 0.844163L0.77474 0.822989C0.771509 0.821132 0.767789 0.822605 0.765743 0.826558L0.731615 0.892511C0.727328 0.900789 0.717947 0.896563 0.717947 0.886347V0.811716C0.717947 0.801121 0.707992 0.797163 0.703989 0.806168L0.65606 0.913942C0.654683 0.917037 0.652257 0.918921 0.649645 0.918921H0.64057C0.63817 0.918921 0.635913 0.920511 0.634491 0.923211L0.60066 0.9874C0.597487 0.993421 0.590951 0.993042 0.588147 0.986674L0.546113 0.891247C0.542906 0.883963 0.53514 0.884774 0.53274 0.892637L0.501955 0.993595C0.50077 0.997479 0.498034 1 0.495008 1Z"></path>
        </clipPath>
      </svg>
    </ClippedImageStyled>
  );
}
