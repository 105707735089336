import React from 'react';
import { PrismicLink, PrismicLinkProps } from '@prismicio/react';
import Container from '@components/container';
import MenuCard from '@components/cards/menu';
import { Grid, useTheme, View } from '@aws-amplify/ui-react';

import styled, { css } from 'styled-components';
import { media } from '@theme/mediaQueries';
import { linkResolver } from '../../prismicio';


const SubMenuLinkStyled = styled(PrismicLink)<PrismicLinkProps>`
  font-weight: bold;
  transition: color 0.2s ease-in-out;

  &:hover {
    ${(props) =>
      css`
        color: ${props.theme.tokens.colors.wwiGreen.value};
      `}
  }
`;

const SubMenuGridStyled = styled(Grid)`
  padding-bottom: 0.625rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1.25rem;

  ${media('medium')`
    margin-bottom: 1.5rem;
  `}
`;

/**
 * @typedef {import("@prismicio/client").Content.LinksSlice} LinksSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<LinksSlice>} LinksProps
 * @param { LinksProps }
 */
const Links = (props: any) => {
  const { items, link, bottom_text } = props;
  const theme = useTheme();

  return (
    <View padding={{ base: '0.875rem 0 2.5rem', medium: '2.5rem 0' }}>
      <Container>
        <View margin={{ base: '0 -25px', medium: '0' }}>
          <SubMenuGridStyled
            templateColumns={{ base: '1fr 1fr', medium: '1fr 1fr 1fr 1fr' }}
            gap={{
              base: theme.tokens.space.medium,
              medium: theme.tokens.space.large,
            }}
          >
            {items.map((item: any) => (
              <MenuCard
                key={item.id}
                uid={item.id}
                title={item.data.title[0]?.text}
                image={item.data.image || null}
                link={linkResolver(item)}
                alias={item.data.canonical_path}
                description={item.data.meta_description}
              />
            ))}
          </SubMenuGridStyled>
          <SubMenuLinkStyled field={link} theme={theme} linkResolver={linkResolver}>
            {bottom_text}
          </SubMenuLinkStyled>
        </View>
      </Container>
    </View>
  );
};

export default Links;
