import { Heading } from '@aws-amplify/ui-react';
import { media } from '../../theme/mediaQueries';
import styled from 'styled-components';

const HeadingStyles = styled(Heading)`
  font-family: var(--fonts-default-title);
  margin-bottom: 1rem;

  &.amplify-heading {
    letter-spacing: -0.01em;

    &--1 {
      font-size: var(--font-size-h1--mobile);
      line-height: 1.22;
      font-weight: 600;
    }

    &--2 {
      line-height: 1.4;
      font-weight: 600;
    }

    &--3 {
      font-size: var(--font-size-h3);
      line-height: 1.4;
      font-weight: 600;
    }

    &--4 {
      font-size: var(--font-size-h4--mobile);
    }

    &--5 {
      font-size: var(--font-size-h5);
    }

    &--4,
    &--5 {
      font-family: var(--fonts-default-html);
      font-weight: 700;
    }
  }

  ${media('medium')`
    &.amplify-heading {
      &--1 {
        font-size: var(--font-size-h1);
        line-height: 1.2;
      }

      &--4 {
        font-size: var(--font-size-h4);
      }
    }
  `};
`;

const HtmlTitle = ({ children, type, customClass }: any) => {
  let heading: any = 2;

  switch (type) {
    case 'heading1':
      heading = 1;
      break;
    case 'heading2':
      heading = 2;
      break;
    case 'heading3':
      heading = 3;
      break;
    case 'heading4':
      heading = 4;
      break;
    case 'heading5':
      heading = 5;
      break;
    case 'heading6':
      heading = 6;
      break;
  }

  return (
    <HeadingStyles className={customClass} level={heading}>
      {children}
    </HeadingStyles>
  );
};

export default HtmlTitle;
