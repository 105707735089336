import { Link, useTheme } from '@aws-amplify/ui-react';
import { PrismicRichText } from '@prismicio/react';
import { DefaultImage } from '../image/defaultImage';
import HtmlTitle from '../title/htmlTitle';

const HtmlText = ({ field, customClass, backgroundColor }: any) => {
  const theme = useTheme();

  return (
    <PrismicRichText
      field={field}
      components={{
        hyperlink: ({ children, node }: any) => (
          <Link
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              color: theme.tokens.colors.textLink.value,
              textDecoration: 'underline',
            }}
            href={node.data.url.replace('https://internal', '')}
          >
            {children}
          </Link>
        ),
        image: ({ node }: any) => (
          <>
            <DefaultImage
              src={node.url}
              alt={node.alt}
              width={node.dimensions.width}
              height={node.dimensions.height}
              background={backgroundColor}
              copyright={node.copyright}
              position="overlay-top"
              type="htmlImage"
            />
          </>
        ),
        heading1: ({ children }) => (
          <HtmlTitle customClass={customClass} type="heading1">
            {children}
          </HtmlTitle>
        ),
        heading2: ({ children }) => (
          <HtmlTitle customClass={customClass} type="heading2">
            {children}
          </HtmlTitle>
        ),
        heading3: ({ children }) => (
          <HtmlTitle customClass={customClass} type="heading3">
            {children}
          </HtmlTitle>
        ),
        heading4: ({ children }) => (
          <HtmlTitle customClass={customClass} type="heading4">
            {children}
          </HtmlTitle>
        ),
        heading5: ({ children }) => (
          <HtmlTitle customClass={customClass} type="heading5">
            {children}
          </HtmlTitle>
        ),
        heading6: ({ children }) => (
          <HtmlTitle customClass={customClass} type="heading6">
            {children}
          </HtmlTitle>
        ),
        paragraph: ({ children }) => <p className={customClass}>{children}</p>,
      }}
    />
  );
};

export default HtmlText;
